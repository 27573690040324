import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";
import { ClustersService } from "../../clusters/clusters.service";
import { ChannelsService } from "../../channels/channels.service";

import { ZixiPullTarget, ZixiPushTarget, TargetApiType, DeliveryChannel } from "../../channels/channel";
import { AWSAccount } from "../../clusters/cluster";
import { InputNIC, BroadcasterOutput, OutputNIC, MediaConnectFlow } from "src/app/models/shared";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";

import { ReceiverOutputPipe } from "../../../pipes/receiver-output.pipe";
import { BroadcasterOutputPipe } from "src/app/pipes/broadcaster-output.pipe";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";
import { ZecsService } from "../../zecs/zecs.service";
import { Zec } from "../../zecs/zecs/zec";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { AmazonAWSMediaConnectFlow } from "../../configuration/amazon-aws/amazon-aws";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import { BondedLink, TargetBondedLink } from "@zixi/models";
@Component({
    selector: "app-target-zixi-form",
    templateUrl: "./target-zixi-form.component.html"
})
export class TargetZixiFormComponent implements OnInit, OnDestroy {
    target: ZixiPullTarget | ZixiPushTarget;
    targetId: number;
    targetName: string;
    targetType: string;
    targetApiType: TargetApiType;
    targetNames: string[];
    existingTarget: ZixiPullTarget | ZixiPushTarget;
    action: string;

    objectType: string;
    subtype: string;
    id: string;

    ingestURLs: string[];
    targetInputNics: string[] = [];

    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    channelType: "mediaconnect_flow" | "passthrough_channel" = "passthrough_channel";
    selectedChannelID: number | null = null;
    startDisabled = false;
    constants = Constants;

    private targetsSubscription: Subscription;
    private channelsSubscription: Subscription;

    applicationHost: string;
    awsRegions = Constants.awsRegions;

    awsAccounts: AWSAccount[];
    accountsLoading: boolean;
    mediaconnectFlowsLoading = false;
    mediaconnectFlows: AmazonAWSMediaConnectFlow[] = [];

    type: string;
    zixiEncryptionType: string;
    mode: string;
    useNameAsStreamId: boolean;
    pid_mapping_profile_id: number | null;

    pullMode: "single" | "multi" = "single";

    receiverDetailsLoading: boolean;
    receiverOutputs: OutputNIC[] = [];
    receiverOutputIds: string[] = [];
    inputNICs: InputNIC[] = [];

    broadcasterDetailsLoading: boolean;
    broadcasterOutputs: BroadcasterOutput[] = [];
    broadcasterOutputIds: string[] = [];
    selectedFlow: MediaConnectFlow | null = null;
    knownFlows: MediaConnectFlow[] = [];
    vpcNamesOnFlow: string[] | null = null;
    selectedFlowVPCName: string | null = null;
    showMoreOptions = false;
    mergeModeValue: "rtp" | "none" | "content" | null | "";
    isFailoverChannelSelected = false;
    disableChannelTypeSelection = false;

    bondingMode: "alt_host" | "auto_bonding" | "custom_bonding" = "alt_host";

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private userService = inject(UsersService);
    private clusterService = inject(ClustersService);
    private receiverService = inject(ZecsService);
    private broadcasterService = inject(BroadcastersService);
    private zecService = inject(ZecsService);
    private rop = inject(ReceiverOutputPipe);
    private bop = inject(BroadcasterOutputPipe);
    private modalService = inject(ModalService);
    private ts = inject(TargetsService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private cbs = inject(ClipboardService);
    private location = inject(Location);
    private cs = inject(ChannelsService);
    private aas = inject(AmazonAwsService);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }
        if (this.action && this.target) {
            this.tagsControl.setValue(this.target.resourceTags);
            this.initShowMoreFailoverOptions();

            if (this.target.pid_mapping) {
                this.pid_mapping_profile_id = this.target.pid_mapping.id;
            }

            if (this.isEdit || this.isClone) {
                this.channelType = this.target.mediaconnect_flow_id ? "mediaconnect_flow" : "passthrough_channel";
                this.selectedChannelID = this.target.mediaconnect_flow_id || this.target.delivery_channel_id;
                if (this.isEdit) {
                    this.nameControl.setValue(this.target.name);
                } else {
                    this.target.name = "";
                    this.target.muted = this.target.active_mute ? 1 : 0;
                }
            }

            if (this.target) {
                if (this.target instanceof ZixiPushTarget) {
                    if (this.target.zixi_encryption_key)
                        this.zixiEncryptionType = "aes" + this.target.zixi_encryption_key.length * 4;
                    else this.zixiEncryptionType = "none";

                    if (this.target.mediaconnect_flow_arn != null) this.type = "mediaconnect";
                    else {
                        this.type = "push";
                        if (this.target.mediaconnectFlow) {
                            this.vpcNamesOnFlow = this.target.mediaconnectFlow.vpc.map(v => v.name);
                            if (this.vpcNamesOnFlow.length === 0) this.vpcNamesOnFlow = null;
                        }
                        this.selectedFlowVPCName =
                            this.target.vpc && this.target.vpc.length === 1 ? this.target.vpc[0].name : null;
                    }

                    if (this.target.bondedLinks?.length > 0) {
                        this.bondingMode = "custom_bonding";
                    } else if (this.target.is_bonding) {
                        this.bondingMode = "auto_bonding";
                    } else {
                        this.bondingMode = "alt_host";
                    }
                }

                if (this.target instanceof ZixiPullTarget) {
                    if (this.target.zixi_encryption_key)
                        this.zixiEncryptionType = "aes" + this.target.zixi_encryption_key.length * 4;
                    else this.zixiEncryptionType = "none";

                    if (this.target.receiver_id) {
                        this.mode = "config-rx";
                        this.getReceiverDetails(this.target.receiver_id);
                    } else if (this.target.broadcaster_id) {
                        this.mode = "config-bx";
                        this.getBroadcasterDetails(this.target.broadcaster_id);
                    } else if (this.target.zec_id) {
                        this.mode = "config-zec";
                        this.getZecDetails(this.target.zec_id);
                    } else {
                        this.mode = "monitor";
                    }

                    this.type = "pull";
                    if (this.target.input_nic && this.target.input_nic !== "")
                        this.targetInputNics = (this.target.input_nic || "").split(",");
                }
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        } else if (this.target && this.target.dynamic_targets) {
            this.pullMode = "multi";
            this.selectPullMode(this.pullMode);
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);
    }

    resetForm() {
        // Channel
        this.target = new ZixiPullTarget();
        // UI
        this.zixiEncryptionType = "none";
        this.mode = "monitor";
        this.type = "pull";
        this.target.merge_window_ms = 5000;
        this.target.billing_code = null;
        this.target.billing_password = null;
        this.targetInputNics = [];
        this.selectedFlow = null;
        this.vpcNamesOnFlow = null;
        this.selectedFlowVPCName = null;
        this.target.mtu = null;
        this.target.autopull_mtu = null;
        this.target.location = {};
        this.mergeModeValue = "";
        this.bondingMode = "alt_host";
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        if (params.targetType) this.targetType = params.targetType;

        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.objectType = params.objecttype;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId && this.targetType) {
            if (this.targetType === "push") this.targetApiType = TargetApiType.Push;
            else this.targetApiType = TargetApiType.Pull;

            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, this.targetApiType));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(this.targetApiType).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, this.targetApiType));
                await this.ts.refreshTarget(this.targetApiType, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, this.targetApiType));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(this.targetApiType, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, this.targetApiType));
            }
        }

        if (anyTarget && (anyTarget.target instanceof ZixiPushTarget || anyTarget.target instanceof ZixiPullTarget)) {
            this.target = anyTarget.target;
            this.deliveryChannelSelected(this.target.delivery_channel_id, this.target?.deliveryChannel);
            this.existingTarget = _.cloneDeep(this.target);
        }

        if (this.objectType === "channel" && this.subtype && this.id) {
            this.selectedChannelID = parseInt(this.id, 10);
            if (this.subtype === "delivery") {
                this.channelType = "passthrough_channel";
            } else if (this.subtype === "mediaconnect") {
                this.channelType = "mediaconnect_flow";
            }
        }

        // Get All Targets
        this.ts.getAllTargets();
        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.Pull || t.apiType === TargetApiType.Push),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });

        //  Flows
        this.cs.getMediaConnectFlows();
        this.channelsSubscription = this.cs.mediaconnectFlows.subscribe((channels: MediaConnectFlow[]) => {
            this.knownFlows = channels;

            if (this.target && this.selectedChannelID) {
                this.AWSflowSelected();
            }
        });

        this.userService.user.pipe(take(1)).subscribe(u => (this.applicationHost = u.application_host));
        // Get AWS accounts
        this.accountsLoading = true;
        await this.getAWSAccounts();
        this.accountsLoading = false;
        //
        this.prepForm();

        this.loading = false;
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
        this.channelsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        let model: Record<string, unknown> = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            location: this.target.location.address,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            preferred_source: this.target.preferred_source,
            mtu: this.target.mtu,
            autopull_mtu: this.target.autopull_mtu,
            pid_mapping_profile_id:
                !(this.channelType === "mediaconnect_flow") && ["pull", "push"].includes(this.type)
                    ? this.pid_mapping_profile_id
                    : null,
            billing_code: this.target.billing_code,
            billing_password: this.target.billing_password,
            external_id: this.target.external_id
        };

        if (this.type === "pull" && this.target instanceof ZixiPullTarget) {
            this.target.merge_mode = !!this.mergeModeValue ? this.mergeModeValue : null;
            let nicsChanged = this.isEdit;
            if (this.isEdit && this.existingTarget instanceof ZixiPullTarget) {
                const hasNicConfig = this.existingTarget.input_nic && this.existingTarget.input_nic !== "";
                if (hasNicConfig && this.targetInputNics.length === 0) nicsChanged = true;
                else if (!hasNicConfig && this.targetInputNics.length !== 0) nicsChanged = true;
                else
                    nicsChanged = hasNicConfig
                        ? this.existingTarget.input_nic.split(",").length !== this.targetInputNics.length ||
                          _.difference(this.existingTarget.input_nic.split(","), this.targetInputNics).length !== 0
                        : this.targetInputNics && this.targetInputNics.length > 0;
            }

            if (nicsChanged) model.input_nic = this.targetInputNics.join(",");

            model = _.extend(model, {
                password: this.target.password,
                receiver_id: this.mode === "config-rx" ? this.target.receiver_id : null,
                broadcaster_id: this.mode === "config-bx" ? this.target.broadcaster_id : null,
                zec_id: this.mode === "config-zec" ? this.target.zec_id : null,
                output_id:
                    this.mode === "config-rx" && this.receiverOutputIds
                        ? this.receiverOutputIds.join(",")
                        : this.mode === "config-bx" && this.broadcasterOutputIds
                        ? this.broadcasterOutputIds.join(",")
                        : this.mode === "config-zec" && this.broadcasterOutputIds
                        ? this.broadcasterOutputIds.join(",")
                        : null,
                receiver_name: this.mode === "monitor" ? this.target.receiver_name : null,
                latency: this.target.latency ? this.target.latency : null,
                zixi_encryption_key: this.zixiEncryptionType === "none" ? "" : this.target.zixi_encryption_key,
                use_private_ip: this.target.use_private_ip,
                merge_mode: this.target.merge_mode,
                merge_window_ms: this.target.merge_window_ms,
                delivery_channel_id: this.channelType === "passthrough_channel" ? this.selectedChannelID : null,
                mediaconnect_flow_id: this.channelType === "mediaconnect_flow" ? this.selectedChannelID : null,
                dynamic_targets: this.pullMode === "multi" ? 1 : 0,
                disable_redirection: this.target.disable_redirection
            });

            if (!this.isEdit && this.useNameAsStreamId === true) model.stream_id = this.target.name;

            // create new output names only if the output ids selection changed
            if (this.mode === "config-rx") {
                if (
                    Array.isArray(this.receiverOutputIds) &&
                    (!this.isEdit ||
                        (this.existingTarget instanceof ZixiPullTarget &&
                            !_.isEqual(this.receiverOutputIds.join(","), this.existingTarget.output_id)))
                ) {
                    model = _.extend(model, {
                        output_name: _.map(this.receiverOutputIds, outId => {
                            const rxOut = _.find(this.receiverOutputs, { id: outId });
                            return rxOut ? this.rop.transform(rxOut) : outId;
                        }).join(", ")
                    });
                }
            } else if (this.mode === "config-bx" || this.mode === "config-zec") {
                if (
                    Array.isArray(this.broadcasterOutputIds) &&
                    (!this.isEdit ||
                        (this.existingTarget instanceof ZixiPullTarget &&
                            !_.isEqual(this.broadcasterOutputIds.join(","), this.existingTarget.output_id)))
                ) {
                    model = _.extend(model, {
                        output_name: _.map(this.broadcasterOutputIds, outId => {
                            const bxOut = _.find(this.broadcasterOutputs, { id: outId });
                            return bxOut ? this.bop.transform(bxOut) : outId;
                        }).join(", ")
                    });
                }
            }
        } else if (this.target instanceof ZixiPushTarget) {
            if (this.type === "mediaconnect")
                model = _.extend(model, {
                    is_bonding: 0,
                    target: this.target.target ?? "",
                    alt_target: "",
                    stream_id: "",
                    password: "",
                    latency: this.target.latency,
                    aws_account_id: this.target.aws_account_id,
                    region: this.target.region,
                    mediaconnect_flow_arn: this.target.mediaconnect_flow_arn,
                    delivery_channel_id: this.selectedChannelID || null
                });
            else
                model = _.extend(model, {
                    is_bonding: this.bondingMode === "auto_bonding",
                    target: this.bondingMode === "custom_bonding" ? "" : this.target.target,
                    alt_target: !this.target.is_bonding ? this.target.alt_target : null,
                    stream_id: this.target.stream_id,
                    password: this.target.password,
                    latency: this.target.latency,
                    zixi_encryption_key: this.zixiEncryptionType === "none" ? "" : this.target.zixi_encryption_key,
                    delivery_channel_id: this.channelType === "passthrough_channel" ? this.selectedChannelID : null,
                    mediaconnect_flow_id: this.channelType === "mediaconnect_flow" ? this.selectedChannelID : null,
                    ignore_dtls_cert: this.target.ignore_dtls_cert,
                    vpc: this.selectedFlowVPCName,
                    bondedLinks: this.bondingMode === "custom_bonding" ? this.target.bondedLinks : null
                });
        }

        const apiType = this.type === "pull" ? TargetApiType.Pull : TargetApiType.Push;
        if (this.isEdit) {
            const objects = {
                resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" },
                pid_mapping_profile_id: { objectsKey: "pid_mapping", valuePath: "id" },
                location: { objectsKey: "location", valuePath: "" },
                vpc: { objectsKey: "vpc", valuePath: null }
            };

            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.target, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart zixi target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(this.targetId, apiType, this.nameControl.value)
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update zixi target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, apiType, this.nameControl.value)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularTargetUrl(this.targetId, apiType, this.nameControl.value));
            }
        } else {
            const result = await this.ts.addTarget(model, apiType);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create zixi target");
                this.router.navigate(urlBuilder.getRegularTargetUrl(result.id, apiType, result.name));
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, this.targetType, this.nameControl.value)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }

    selectPullType(type: string) {
        const currentData = Object.assign({}, this.target);
        if (type === "mediaconnect") this.channelType = "passthrough_channel";

        if (type === "push" || type === "mediaconnect") {
            this.target = new ZixiPushTarget();
            this.target.is_bonding = 0;
            this.bondingMode = "alt_host";
        } else {
            this.target = new ZixiPullTarget();
        }

        Object.assign(this.target, currentData);
        this.selectedChannelID = null;

        this.selectPullMode(this.pullMode);
    }

    selectPullMode(mode: "single" | "multi") {
        if (mode === "multi") {
            this.channelType = "passthrough_channel";
            this.selectedChannelID = null;
            this.mode = "monitor";
            this.useNameAsStreamId = false;
            this.mergeModeValue = "";
            Object.assign(this.target, {
                receiver_name: "",
                receiver_id: null,
                broadcaster_id: null,
                zec_id: null,
                mediaconnect_flow_id: null
            });
        }
    }

    async getAWSAccounts() {
        const result = await this.clusterService.getAWSAccounts();
        if (result) {
            this.awsAccounts = result;
            return this.awsAccounts;
        } else {
            this.awsAccounts = [];
            return this.awsAccounts;
        }
    }

    async getBroadcasterDetails(id: number) {
        this.broadcasterOutputs = [];
        this.inputNICs = [];
        this.broadcasterOutputIds = [];

        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }

        const broadcaster = await this.broadcasterService.refreshBroadcaster(id, true).toPromise();
        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.outputs) {
                this.broadcasterOutputs = broadcaster.status.outputs || [];
                if (this.target instanceof ZixiPullTarget) {
                    this.broadcasterOutputIds = this.target.output_id
                        ? this.target.output_id
                              .split(",")
                              .map(o => o.trim())
                              .filter(oid => this.broadcasterOutputs.find(o => o.id === oid))
                        : null;
                }
            } else {
                this.broadcasterOutputs = [];
            }
            if (broadcaster.status.nics) {
                this.inputNICs = (broadcaster.status.nics || []).filter(nic => nic.name !== "Any");
                if (this.target instanceof ZixiPullTarget) if (!this.target.input_nic) this.target.input_nic = "";
            } else {
                this.inputNICs = [];
                if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            }
        } else {
            this.inputNICs = [];
            if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            this.broadcasterOutputIds = null;
        }

        this.broadcasterDetailsLoading = false;
    }

    async getZecDetails(id: number) {
        this.broadcasterOutputs = [];
        this.inputNICs = [];
        this.broadcasterOutputIds = [];

        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }

        const zec = await this.zecService.refreshZec(id, "ZEC", true).toPromise();
        if (zec && zec.status) {
            if ((zec as Zec).status.outputs) {
                this.broadcasterOutputs = (zec as Zec).status.outputs || [];
                if (this.target instanceof ZixiPullTarget) {
                    this.broadcasterOutputIds = this.target.output_id
                        ? this.target.output_id
                              .split(",")
                              .map(o => o.trim())
                              .filter(oid => this.broadcasterOutputs.find(o => o.id === oid))
                        : null;
                }
            } else {
                this.broadcasterOutputs = [];
            }
            if (zec.status.nics) {
                this.inputNICs = (zec.status.nics || []).filter(nic => nic.name !== "Any");
                if (this.target instanceof ZixiPullTarget) if (!this.target.input_nic) this.target.input_nic = "";
            } else {
                this.inputNICs = [];
                if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            }
        } else {
            this.inputNICs = [];
            if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            this.broadcasterOutputIds = null;
        }

        this.broadcasterDetailsLoading = false;
    }

    async getReceiverDetails(id: number) {
        this.receiverOutputs = [];
        this.inputNICs = [];
        this.receiverOutputIds = [];

        this.receiverDetailsLoading = true;
        if (!id) {
            this.receiverDetailsLoading = false;
            return;
        }

        const receiver = await this.receiverService.refreshZec(id, "RECEIVER", true).toPromise();

        if (receiver && receiver.status) {
            if (receiver.status.outputs) {
                this.receiverOutputs = receiver.status.outputs || [];
                if (this.target instanceof ZixiPullTarget) {
                    this.receiverOutputIds = this.target.output_id
                        ? this.target.output_id
                              .split(",")
                              .map(o => o.trim())
                              .filter(oid => this.receiverOutputs.find(o => o.id === oid))
                        : null;
                }
            } else {
                this.receiverOutputs = [];
            }
            if (receiver.status.nics) {
                this.inputNICs = (receiver.status.nics || []).filter(nic => nic.name !== "Any");
                if (this.target instanceof ZixiPullTarget) if (!this.target.input_nic) this.target.input_nic = "";
            } else {
                this.inputNICs = [];
                if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            }
        } else {
            this.inputNICs = [];
            if (this.target instanceof ZixiPullTarget) this.target.input_nic = "";
            this.receiverOutputIds = null;
        }

        this.receiverDetailsLoading = false;
    }

    generatePassword() {
        if (this.target instanceof ZixiPullTarget) this.target.password = this.sharedService.generateStrongPassword();
    }

    zixiEncryptionKeyLen(type: string) {
        return type === "aes256" ? 64 : type === "aes192" ? 48 : type === "aes128" ? 32 : 0;
    }

    generateZixiEncryptionKey(len: number) {
        let key = "";
        for (let i = 0; i < len; i++) key += "abcdef0123456789"[Math.floor(Math.random() * 16)];
        return key;
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    zixiTargetCompatibleFlows() {
        return (flow: MediaConnectFlow): boolean => {
            return (
                (!flow.mediaStreams || flow.mediaStreams.length === 0) &&
                (!flow.source || !["cdi", "st2110-jpegxs"].includes(flow.source.protocol))
            );
        };
    }

    async deliveryChannelSelected(selectedDeliveryChannelId: number, deliveryChannel?: DeliveryChannel) {
        this.disableChannelTypeSelection = true;
        if (this.channelType === "passthrough_channel") {
            if (deliveryChannel) {
                this.isFailoverChannelSelected = !!deliveryChannel.is_hidden;
            } else {
                if (selectedDeliveryChannelId) {
                    this.isFailoverChannelSelected = true;
                    const deliveryChannel = await this.cs.getDeliveryChannel(selectedDeliveryChannelId);
                    this.isFailoverChannelSelected = deliveryChannel ? !!deliveryChannel.is_hidden : false;
                } else {
                    this.isFailoverChannelSelected = false;
                }
            }
        }
        this.disableChannelTypeSelection = false;
    }

    channelTypeChanged() {
        if (this.channelType === "mediaconnect_flow") {
            this.target.preferred_source = 0;
        } else {
            if (this.target instanceof ZixiPushTarget) this.target.is_bonding = this.target.is_bonding ? 1 : 0;
        }
        this.selectedChannelID = null;
        this.isFailoverChannelSelected = false;
    }

    AWSflowSelected() {
        if (this.channelType !== "mediaconnect_flow") return;
        this.selectedFlowVPCName = null;
        this.selectedFlow = this.knownFlows.find(f => f.id === this.selectedChannelID);
        if (!this.selectedFlow) {
            this.vpcNamesOnFlow = null;
            return;
        }

        this.vpcNamesOnFlow = this.selectedFlow.vpc.map(v => v.name);
        if (this.vpcNamesOnFlow.length === 0) this.vpcNamesOnFlow = null;
    }

    initShowMoreFailoverOptions(): void {
        if (this.isEdit || this.isClone) {
            if (this.target instanceof ZixiPullTarget) {
                this.mergeModeValue = !!this.target.merge_mode ? this.target.merge_mode : "";
            }
            this.showMoreOptions = this.mergeModeValue === "rtp" || this.mergeModeValue === "content";
        }
    }

    async getAWSMediaConnectFlows(id: number, region: string) {
        this.mediaconnectFlows = [];
        this.mediaconnectFlowsLoading = true;
        if (!id) {
            this.mediaconnectFlowsLoading = false;
            return;
        }

        const flows = await this.aas.getAWSAccountMediaConnectFlows(id, region);
        if (flows) this.mediaconnectFlows = flows;

        this.mediaconnectFlowsLoading = false;
    }

    onDeviceChange() {
        if (this.target instanceof ZixiPullTarget) {
            this.target.receiver_id = null;
            this.target.broadcaster_id = null;
            this.target.zec_id = null;
            this.mergeModeValue = "";
            this.showMoreOptions =
                (this.mode === "config-bx" || this.mode === "config-zec") &&
                (!!this.target.broadcaster_id || !!this.target.zec_id);
            if (this.mode !== "monitor") this.target.disable_redirection = false;
        }
    }

    addBondedLink() {
        if (!(this.target instanceof ZixiPushTarget)) return;

        const blankLink = {
            host: "",
            port: 2088,
            nic_cidr: "",
            max_bitrate: 0,
            backup: false
        };
        if (!this.target.bondedLinks) {
            this.target.bondedLinks = [blankLink];
        } else {
            this.target.bondedLinks.push(blankLink);
        }
    }

    deleteBondedLink(index: number) {
        if (!(this.target instanceof ZixiPushTarget)) return;
        this.target.bondedLinks.splice(index, 1);
    }

    zecSearch(term: string, item: BroadcasterOutput) {
        return item.name.toLowerCase().includes(term.toLowerCase());
    }
}
