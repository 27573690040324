import { Component, Input, inject } from "@angular/core";
import { Source } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-target-stream",
    templateUrl: "./target-stream.component.html"
})
export class TargetStreamComponent {
    @Input() source: Source;

    showOverlay = true;
    urls = Constants.urls;
    private deviceService = inject(DeviceDetectorService);
    canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
}
