<app-table-list
    [tableName]="'target_transcoding_profile'"
    [displayTableName]="'TRANSCODING_PROFILES' | translate"
    [excelReportTitle]="target.target.name + ' ' + ('TRANSCODING_PROFILES' | translate)"
    [data]="transcodingProfiles$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [isColumnSelectionNeeded]="false"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="autoRows"
></app-table-list>
