import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { TargetComponent } from "./target/target.component";
import { TargetListComponent } from "./target-list/target-list.component";
import { TargetFormComponent } from "./target-form/target-form.component";
import { TargetHttpFormComponent } from "./target-http-form/target-http-form.component";
import { TargetMediaLiveHttpFormComponent } from "./target-medialive-http-form/target-medialive-http-form";
import { TargetUdpRtpFormComponent } from "./target-udp-rtp-form/target-udp-rtp-form.component";
import { TargetZixiFormComponent } from "./target-zixi-form/target-zixi-form.component";
import { TargetRtmpFormComponent } from "./target-rtmp-form/target-rtmp-form.component";
import { TargetRistFormComponent } from "./target-rist-form/target-rist-form.component";
import { TargetSrtFormComponent } from "./target-srt-form/target-srt-form.component";
import { TargetNdiFormComponent } from "./target-ndi-form/target-ndi-form.component";
import { TargetCDIFormComponent } from "./target-cdi-form/target-cdi-form.component";
import { TargetJPEGXSFormComponent } from "./target-jpegxs-form/target-jpegxs-form.component";
import { TargetListResolverService } from "./target-list/target-list-resolver.service";
import { TargetEntitlementFormComponent } from "./target-entitlement-form/target-entitlement-form.component";
import { TargetBreadcrumbsResolverService, TargetFormBreadcrumbsResolverService } from "./target-resolver.service";

const targetRoutes: Routes = [
    {
        path: Constants.urls.targets + "/new",
        component: TargetFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PULL_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/4Z5s0jVv"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PUSH_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/QTqBNRhA"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/nDXt9nSY"
                },
                {
                    menu: "HELP_MENU.NEW_RIST_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT"
                },
                {
                    menu: "HELP_MENU.NEW_UDPRTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn"
                },
                {
                    menu: "HELP_MENU.NEW_RTMP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS"
                },
                {
                    menu: "HELP_MENU.NEW_SRT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0"
                },
                {
                    menu: "HELP_MENU.NEW_NDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7"
                },
                {
                    menu: "HELP_MENU.NEW_CDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE"
                },
                {
                    menu: "HELP_MENU.NEW_JPEGXS_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/new/:type/:subtype/:id",
        component: TargetFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PULL_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/4Z5s0jVv"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PUSH_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/QTqBNRhA"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/nDXt9nSY"
                },
                {
                    menu: "HELP_MENU.NEW_RIST_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT"
                },
                {
                    menu: "HELP_MENU.NEW_UDPRTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn"
                },
                {
                    menu: "HELP_MENU.NEW_RTMP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS"
                },
                {
                    menu: "HELP_MENU.NEW_SRT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0"
                },
                {
                    menu: "HELP_MENU.NEW_NDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7"
                },
                {
                    menu: "HELP_MENU.NEW_CDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE"
                },
                {
                    menu: "HELP_MENU.NEW_JPEGXS_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.http + "/new",
        component: TargetHttpFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New HTTP Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.http + "/new/:type/:subtype/:id",
        component: TargetHttpFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.medialive_http + "/new",
        component: TargetMediaLiveHttpFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New MediaLive HTTP Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.medialive_http + "/new/:type/:subtype/:id",
        component: TargetMediaLiveHttpFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.zixi + "/new",
        component: TargetZixiFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New Zixi Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ZIXI_PULL_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/4Z5s0jVv"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PUSH_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/QTqBNRhA"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/nDXt9nSY"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.zixi + "/new/:objecttype/:subtype/:id",
        component: TargetZixiFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_ZIXI_PULL_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/4Z5s0jVv"
                },
                {
                    menu: "HELP_MENU.NEW_ZIXI_PUSH_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/QTqBNRhA"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/nDXt9nSY"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rtmp + "/new",
        component: TargetRtmpFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New RTMP Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_RTMP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rtmp + "/new/:type/:subtype/:id",
        component: TargetRtmpFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RTMP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.udp_rtp + "/new",
        component: TargetUdpRtpFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New UDP/RTP Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_UDPRTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.udp_rtp + "/new/:type/:subtype/:id",
        component: TargetUdpRtpFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_UDPRTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rist + "/new",
        component: TargetRistFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New RIST Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_RIST_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rist + "/new/:type/:subtype/:id",
        component: TargetRistFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RIST_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.srt + "/new",
        component: TargetSrtFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New SRT Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_SRT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.srt + "/new/:type/:subtype/:id",
        component: TargetSrtFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_SRT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.ndi + "/new",
        component: TargetNdiFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New NDI Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_NDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.ndi + "/new/:type/:subtype/:id",
        component: TargetNdiFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_NDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.cdi + "/new",
        component: TargetCDIFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New CDI Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_CDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.cdi + "/new/:type/:subtype/:id",
        component: TargetCDIFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_CDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.jpegxs + "/new",
        component: TargetJPEGXSFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New ST 2110 JPEG XS Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_JPEGXS_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.jpegxs + "/new/:type/:subtype/:id",
        component: TargetJPEGXSFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_JPEGXS_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.entitlement + "/new",
        component: TargetEntitlementFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                },
                {
                    label: "New Target",
                    url: `/${Constants.urls.targets}/new`
                },
                {
                    label: "New Entitlement Target"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ENTITLEMENT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYDgHwE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.entitlement + "/new/:type/:subtype/:id",
        component: TargetEntitlementFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_ENTITLEMENT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYDgHwE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.entitlement + "/:targetId/:name/:action",
        component: TargetEntitlementFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_ENTITLEMENT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYDgHwE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rtmp + "/:targetId/:name/:action",
        component: TargetRtmpFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RTMP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.http + "/:targetId/:name/:action",
        component: TargetHttpFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.medialive_http + "/:targetId/:name/:action",
        component: TargetMediaLiveHttpFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_HTTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.udp_rtp + "/:targetId/:name/:action",
        component: TargetUdpRtpFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_UDPRTP_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.rist + "/:targetId/:name/:action",
        component: TargetRistFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RIST_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.srt + "/:targetId/:name/:action",
        component: TargetSrtFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_SRT_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.ndi + "/:targetId/:name/:action",
        component: TargetNdiFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_NDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.cdi + "/:targetId/:name/:action",
        component: TargetCDIFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_CDI_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/" + Constants.urls.targetTypes.jpegxs + "/:targetId/:name/:action",
        component: TargetJPEGXSFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_JPEGXS_TARGET",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE"
                }
            ]
        }
    },
    {
        path: Constants.urls.targets + "/:targetType/:targetId/:name/:action",
        component: TargetZixiFormComponent,
        resolve: {
            breadcrumbs: TargetFormBreadcrumbsResolverService
        },
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.targets,
        canActivate: [AuthGuard],
        component: TargetListComponent,
        resolve: {
            events: TargetListResolverService
        },
        children: [
            {
                path: ":type/:targetId/:name",
                component: TargetComponent,
                resolve: {
                    breadcrumbs: TargetBreadcrumbsResolverService
                }
            }
        ],
        data: {
            breadcrumbs: [
                {
                    label: "Targets",
                    url: `/${Constants.urls.targets}`
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.TARGETS",
                    link: "https://zixidocumentation.atlassian.net/l/c/2QweEkAA"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(targetRoutes)],
    exports: [RouterModule]
})
export class TargetsRouting {}
