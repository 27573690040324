import { Component, OnInit, OnDestroy, ViewChild, ComponentRef, ElementRef } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter, take } from "rxjs/operators";
import { urlBuilder } from "@zixi/shared-utils";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { StatusTextPipe } from "../../../pipes/status-text.pipe";
import { Constants } from "../../../constants/constants";
import { TargetComponent } from "../target/target.component";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { KeyMap, RecoveryState, SomeZixiObject, UserPermissions, ZixiPlus } from "../../../models/shared";
import { TargetsService } from "../targets.service";
import {
    AnyTarget,
    RistTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    TargetApiType,
    TargetObjectType,
    TargetPidMappingTypes,
    PublishingTarget
} from "../../channels/channel";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { ZxBroadcasterComponent } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { ZxTargetTargetsColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.component";
import { ZxTargetChannelColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-channel-column/zx-target-channel-column.component";
//Adapters
import { assignComponentsBroadcastAdapter } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.table-adapter";
import { assignComponentsTargetTargetsAdapter } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.table-adapter";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { assignComponentsStatusInputsFactory } from "../../../components/shared/zx-status-full/zx-status-full.table-adapter";
import { assignComponentsTargetChannelsColumnAdapter } from "src/app/components/shared/table-list/tables-components/zx-target-channel-column/zx-target-channels-column.table-adapter";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { DecimalPipe } from "@angular/common";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TagsService } from "../../configuration/tags/tags.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { ZxChannelTypeComponent } from "src/app/components/shared/zx-channel-type/zx-channel-type.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";

@Component({
    selector: "app-target-list",
    templateUrl: "./target-list.component.html"
})
export class TargetListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) target: RouterOutlet;
    @ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;

    refreshing = false;
    targets: AnyTarget[] = [];
    targetId: number;
    targetName: string;
    selectedRows: Array<AnyTarget> = [];
    userPermissions: UserPermissions;

    urls = Constants.urls;

    isResizing: boolean;
    showInsights = false;

    private targetApiType: TargetApiType;
    private selectedTarget: AnyTarget;

    private tourSteps = TourSteps.targetInsights;

    private currentHttpIDs: number[];
    private currentRtmpIDs: number[];
    private currentPullIDs: number[];
    private currentPushIDs: number[];
    private currentUdpRtpIDs: number[];
    private currentRistIDs: number[];
    private currentSrtIDs: number[];
    private currentNdiIDs: number[];
    private currentCDIIDs: number[];
    private currentJPEGXSIDs: number[];
    private currentMediaLiveHttpIDs: number[];
    private currentEntitlementIDs: number[];

    private routeSubscription: Subscription;
    private targetsSubscription: Subscription;
    private targetsRefreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;

    splitterPosition = null;

    private targetsBS$ = new BehaviorSubject<AnyTarget[]>([]);
    private currentSortDirection: string;

    multiSelectDisasterRecovery = false;

    tableColumnsSchema: TableSchema<KeyMap<AnyTarget>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 160,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => row.target.name,
                row => row.target.name,
                () => true
            ),
            sortBy: row => row.target.name,
            textValue: row => row.target.name,
            valueToExport: row => row.target.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory<KeyMap<AnyTarget>>({
                lockIconTextCallBack: row => this.ts.DTLSNote(row),
                modelCallBack: row => row.target as unknown as Partial<SomeZixiObject>,
                showOtherIcons: true
            }),
            textValue: row => this.translate.instant(this.stp.transform(row.target)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? (row.target as unknown as ZixiPlus)._sortData.sortableStatusAsc
                    : (row.target as unknown as ZixiPlus)._sortData.sortableStatusDesc,
            valueToExport: row => this.translate.instant(this.stp.transform(row.target)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translate.instant(this.stp.simpleTransform(row.target)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translate.instant("ERROR"),
            columnDef: "error",
            hideFromColumnChooser: true,
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => row.target.activeStates?.map(as => as.short_message)?.join(","),
                row => row.target.activeStates?.map(as => as.short_message)?.join(","),
                () => true
            ),
            textValue: row => row.target.activeStates?.map(as => as.short_message)?.join(","),
            valueToExport: row => row.target.activeStates?.map(as => as.short_message)?.join(",")
        },
        {
            header: this.translate.instant("EVENT"),
            columnDef: "message",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row =>
                    row.target.activeStates.length ? row.target.activeStates?.map(as => as.message)?.join(",") : "-",
                row =>
                    row.target.activeStates.length ? row.target.activeStates?.map(as => as.message)?.join(",") : "-",
                () => true
            ),
            textValue: row =>
                row.target.activeStates.length ? row.target.activeStates?.map(as => as.message)?.join(",") : "-",
            valueToExport: row => row.target.activeStates?.map(as => as.message)?.join(","),
            sortBy: row =>
                row.target.activeStates.length ? row.target.activeStates?.map(as => as.message)?.join(",") : "-",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("EXTERNAL_ID"),
            columnDef: "external_id",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            // Add a type here to avoid a type error, even if external_id is empty its ok
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => (row.target as RistTarget).external_id,
                row => (row.target as RistTarget).external_id,
                row => !!(row.target as RistTarget).external_id
            ),
            valueToExport: row => (row.target as RistTarget).external_id,
            textValue: row => (row.target as RistTarget).external_id,
            sortBy: row => (row.target as RistTarget).external_id,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BROADCASTER"),
            columnDef: "active_broadcaster",
            width: 160,
            visible: true,
            component: ZxBroadcasterComponent,
            assignComponentsInputs: assignComponentsBroadcastAdapter,
            textValue: row => row.target.status?.active_broadcaster?.name ?? "-",
            sortBy: row => row.target.status?.active_broadcaster?.name ?? "-",
            valueToExport: row =>
                row.type === "pull"
                    ? row.target.status?.active_broadcasters[0]?.name
                    : row.target.status?.active_broadcaster?.name ?? "",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnyTarget>(
                row => this.translate.instant(row.type_name.toUpperCase()),
                row => this.translate.instant(row.type_name.toUpperCase()),
                () => true
            ),
            textValue: row => this.translate.instant(row.type_name.toUpperCase()),
            sortBy: row => this.translate.instant(row.type_name.toUpperCase()),
            valueToExport: row => this.translate.instant(row.type_name),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TARGET"),
            columnDef: "target",
            width: 160,
            visible: true,
            component: ZxTargetTargetsColumnComponent,
            assignComponentsInputs: assignComponentsTargetTargetsAdapter,
            textValue: row => this.getTargetColumnTextByRow(row),
            sortBy: row => this.getTargetColumnTextByRow(row),
            valueToExport: row => {
                if (!(row.pull && row.target instanceof ZixiPullTarget)) return row.output_target;
                let text = "";
                if (row.target.receiver_id) text += row.target.receiver.name;
                if (row.target.broadcaster_id)
                    text +=
                        row.target.broadcaster.name +
                        (row.target.broadcaster.broadcaster_cluster.name
                            ? " @ " + row.target.broadcaster.broadcaster_cluster.name
                            : "");
                if ((row.target.receiver_id || row.target.broadcaster_id) && row.target.output_id)
                    text += " / " + row.target.output_name;
                if (!row.target.receiver_id && !row.target.broadcaster_id) text += row.target.receiver_name;
                return text;
            },
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("CLIENT_IP"),
            columnDef: "client_ip",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
                () => true
            ),
            sortBy: row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
            textValue: row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
            valueToExport: row => (row.target.status?.ip && row.target.status?.ip !== "" ? row.target.status?.ip : "-"),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<AnyTarget>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform((row.target.status?.stats?.net_bitrate ?? 0) / 1000.0, "1.0-0"),
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: row => {
                const title = this.decimalPipe.transform(
                    (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
                    "1.0-0"
                );
                return title ? `${title}` : "0";
            },
            sortBy: row => row.target.status?.stats?.net_bitrate,
            valueToExport: row => (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row => (row.target.status?.stats?.net_bitrate ?? 0) / 1000.0
        },
        {
            header: this.translate.instant("UP_TIME"),
            columnDef: "up_time",
            width: 120,
            align: "right",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AnyTarget>(
                row => this.uptime.transform(row.target.status?.up_time),
                row => this.uptime.transform(row.target.status?.up_time),
                row => !!row.target.status?.up_time
            ),
            textValue: (row: AnyTarget) => {
                const title = this.uptime.transform(row.target.status?.up_time);
                return row.target.status && row.target.status?.up_time ? title : "-";
            },
            sortBy: (row: AnyTarget) => row.target.status?.up_time,
            valueToExport: (row: AnyTarget) => row.target.status?.up_time,
            columnFilterType: ColumnFilterType.DURATION,
            columnFilterValue: row => row.target.status?.up_time
        },
        {
            header: this.translate.instant("CHANNEL"),
            columnDef: "channel",
            width: 240,
            visible: true,
            component: ZxTargetChannelColumnComponent,
            assignComponentsInputs: assignComponentsTargetChannelsColumnAdapter,
            textValue: row => row.channel_name,
            sortBy: row => row.channel_name,
            valueToExport: row => {
                let channelText = "";
                if (row.target.adaptiveChannel)
                    channelText +=
                        row.target.adaptiveChannel?.name + " @ " + row.target.adaptiveChannel?.processingCluster?.name;
                if (row.target.mediaconnect_flow_id == null && row.delivery && row.target.deliveryChannel)
                    channelText +=
                        row.target.deliveryChannel?.name + " @ " + row.target.deliveryChannel?.processingCluster?.name;
                if (row.target.mediaconnect_flow_id != null)
                    channelText += row.target.mediaconnectFlow?.name + " @ " + row.target.mediaconnectFlow?.region;
                if (row.target.medialive_channel_id != null)
                    channelText += row.target.mediaLiveChannel?.name + " @ " + row.target.mediaLiveChannel?.region;
                return channelText;
            },
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("CHANNEL_TYPE"),
            columnDef: "channel_type",
            width: 160,
            visible: true,
            component: ZxChannelTypeComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ZxChannelTypeComponent>,
                row: KeyMap<AnyTarget>,
                searchTerm: string[]
            ) => {
                componentRef.instance.channelType = row.channel_type || "-";
                componentRef.instance.searchTerm = searchTerm;
            },
            textValue: row => row.channel_type,
            sortBy: row => row.channel_type,
            valueToExport: row => row.channel_type,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("ALT_PATH"),
            columnDef: "alternative",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => (this.ts.isAltPath(row.target) ? "Active" : "Inactive"),
                row => (this.ts.isAltPath(row.target) ? "Active" : "Inactive"),
                () => true
            ),
            sortBy: (row: KeyMap<AnyTarget>) => (this.ts.isAltPath(row.target) ? "Active" : "Inactive"),
            textValue: (row: KeyMap<AnyTarget>) => (this.ts.isAltPath(row.target) ? "Active" : "Inactive"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Active", "Inactive"],
            columnFilterValue: (row: KeyMap<AnyTarget>) => (this.ts.isAltPath(row.target) ? "Active" : "Inactive")
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 160,
            visible: false,
            component: ZxTagsListComponent,
            assignComponentsInputs: (
                targetComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<AnyTarget>,
                searchTerm: string[]
            ) => {
                const targetCompRef = targetComponentRef.instance;
                targetCompRef.model = row.target;
                targetCompRef.searchTerm = searchTerm.toString();
            },
            textValue: row => row.target.resourceTags?.map(t => t.name).join(", "),
            sortBy: row => row.target.resourceTags?.map(t => t.name).join(""),
            valueToExport: row => row.target.resourceTags?.map(t => t.name).join(", "),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 80,
            visible: false,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<AnyTarget>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = this.tagsService.isObjectVip(row.target.resourceTags);
            },
            sortBy: (row: AnyTarget) => (this.tagsService.isObjectVip(row.target.resourceTags) ? "priority" : ""),
            textValue: (row: AnyTarget) => (this.tagsService.isObjectVip(row.target.resourceTags) ? "priority" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (this.tagsService.isObjectVip(row.target.resourceTags) ? "Yes" : "No")
        },
        {
            header: this.translate.instant("MUTED"),
            columnDef: "muted",
            width: 0,
            visible: false,
            textValue: row => (row.target.active_mute ? "muted" : ""),
            sortBy: row => (row.target.active_mute ? "muted" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (row.target.active_mute ? "Yes" : "No")
        },
        {
            header: this.translate.instant("PID_MAPPING"),
            columnDef: "pid_mapping",
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => (row.target as TargetPidMappingTypes).pid_mapping?.name,
                row => (row.target as TargetPidMappingTypes).pid_mapping?.name,
                row => !!(row.target as TargetPidMappingTypes).pid_mapping
            ),
            sortBy: (row: KeyMap<AnyTarget>) => (row.target as TargetPidMappingTypes).pid_mapping?.name,
            textValue: (row: KeyMap<AnyTarget>) => (row.target as TargetPidMappingTypes).pid_mapping?.name,
            valueToExport: (row: KeyMap<AnyTarget>) => (row.target as TargetPidMappingTypes).pid_mapping?.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BILLING_CODE"),
            columnDef: "billing_code",
            width: 80,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row =>
                    (row.target as PublishingTarget)?.billing_code
                        ? (row.target as PublishingTarget).billing_code
                        : "-",
                row =>
                    (row.target as PublishingTarget)?.billing_code
                        ? (row.target as PublishingTarget).billing_code
                        : "-",
                _row => true
            ),
            sortBy: row =>
                (row.target as PublishingTarget)?.billing_code ? (row.target as PublishingTarget).billing_code : "-",
            textValue: row =>
                (row.target as PublishingTarget)?.billing_code ? (row.target as PublishingTarget).billing_code : "-",
            valueToExport: row =>
                (row.target as PublishingTarget)?.billing_code ? (row.target as PublishingTarget).billing_code : "-",
            columnFilterType: ColumnFilterType.STRING
        }
    ];

    constructor(
        private stp: StatusTextPipe,
        private router: Router,
        private ts: TargetsService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private translate: TranslateService,
        public mixpanelService: MixpanelService,
        private titleService: TitleService,
        private decimalPipe: DecimalPipe,
        private uptime: UptimePipe,
        private tagsService: TagsService,
        public tourService: TourService,
        private ngbModal: NgbModal
    ) {
        // Set Title
        this.titleService.setTitle("TARGETS", "");

        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                this.selectedTarget = null;

                if (event.snapshot.params && event.snapshot.params.targetId && event.snapshot.params.type) {
                    this.targetName = event.snapshot.params.name;
                    this.targetId = urlBuilder.decode(event.snapshot.params.targetId);

                    const targetType = event.snapshot.params.type;

                    switch (targetType) {
                        case Constants.urls.targetTypes.http:
                            this.targetApiType = TargetApiType.Http;
                            break;
                        case Constants.urls.targetTypes.pull:
                            this.targetApiType = TargetApiType.Pull;
                            break;
                        case Constants.urls.targetTypes.push:
                            this.targetApiType = TargetApiType.Push;
                            break;
                        case Constants.urls.targetTypes.udp_rtp:
                            this.targetApiType = TargetApiType.UdpRtp;
                            break;
                        case Constants.urls.targetTypes.rtmp:
                            this.targetApiType = TargetApiType.Rtmp;
                            break;
                        case Constants.urls.targetTypes.rist:
                            this.targetApiType = TargetApiType.Rist;
                            break;
                        case Constants.urls.targetTypes.srt:
                            this.targetApiType = TargetApiType.Srt;
                            break;
                        case Constants.urls.targetTypes.ndi:
                            this.targetApiType = TargetApiType.Ndi;
                            break;
                        case Constants.urls.targetTypes.cdi:
                            this.targetApiType = TargetApiType.CDI;
                            break;
                        case Constants.urls.targetTypes.jpegxs:
                            this.targetApiType = TargetApiType.JPEGXS;
                            break;
                        case Constants.urls.targetTypes.medialive_http:
                            this.targetApiType = TargetApiType.MediaLiveHttp;
                            break;
                        case Constants.urls.targetTypes.entitlement:
                            this.targetApiType = TargetApiType.Entitlement;
                            break;
                        default:
                            this.targetId = null;
                            this.targetApiType = null;
                            this.targetName = null;
                            break;
                    }

                    this.updateSelectedTarget();
                } else {
                    this.targetId = null;
                    this.targetName = null;
                    this.targetApiType = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // local storage
        if (localStorage.getItem("splitter-width"))
            this.leftContainer.nativeElement.style.flexBasis = localStorage.getItem("splitter-width");

        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position"), 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targets = targets;
            if (this.targets) {
                this.selectedTarget = this.targets.find(
                    (t: AnyTarget) => t.objId === this.targetId && t.apiType === this.targetApiType
                );
                if (this.selectedTarget) this.selectedRows = [this.selectedTarget];

                this.prepTableData();
            }
        });
        // Start Auto Refresh
        this.startTargetsRefresh();
        setTimeout(() => {
            if (this.targetName) {
                this.updateSelectedTarget();
            } else {
                this.selectedRows = [];
            }
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.targetsSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopTargetsRefresh();
    }

    private updateSelectedTarget() {
        this.selectedTarget = this.targets.find(
            (t: AnyTarget) => t.objId === this.targetId && t.apiType === this.targetApiType
        );
        if (this.selectedTarget) {
            this.selectedRows = [this.selectedTarget];
            this.showInsights = false;
        }
    }

    resizing(v: boolean) {
        this.isResizing = v;
    }

    selectRow = (target: AnyTarget) => {
        this.showInsights = false;
        this.selectedTarget = target;
        this.router.navigate(urlBuilder.getRegularTargetUrl(target.objId, target.apiType, target.target.name));
    };

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    onPagination(targets: AnyTarget[]) {
        this.currentHttpIDs = targets.filter(t => t.apiType === TargetApiType.Http).map(target => target.objId);
        this.currentRtmpIDs = targets.filter(t => t.apiType === TargetApiType.Rtmp).map(target => target.objId);
        this.currentPullIDs = targets.filter(t => t.apiType === TargetApiType.Pull).map(target => target.objId);
        this.currentPushIDs = targets.filter(t => t.apiType === TargetApiType.Push).map(target => target.objId);
        this.currentUdpRtpIDs = targets.filter(t => t.apiType === TargetApiType.UdpRtp).map(target => target.objId);
        this.currentRistIDs = targets.filter(t => t.apiType === TargetApiType.Rist).map(target => target.objId);
        this.currentSrtIDs = targets.filter(t => t.apiType === TargetApiType.Srt).map(target => target.objId);
        this.currentNdiIDs = targets.filter(t => t.apiType === TargetApiType.Ndi).map(target => target.objId);
        this.currentCDIIDs = targets.filter(t => t.apiType === TargetApiType.CDI).map(target => target.objId);
        this.currentJPEGXSIDs = targets.filter(t => t.apiType === TargetApiType.JPEGXS).map(target => target.objId);
        this.currentMediaLiveHttpIDs = targets
            .filter(t => t.apiType === TargetApiType.MediaLiveHttp)
            .map(target => target.objId);
        this.currentEntitlementIDs = targets
            .filter(t => t.apiType === TargetApiType.Entitlement)
            .map(target => target.objId);
    }

    async refresh() {
        this.refreshing = true;

        const httpTargetsRefresh = this.ts.refreshTargets(TargetApiType.Http, this.currentHttpIDs).toPromise();
        const rtmpTargetsRefresh = this.ts.refreshTargets(TargetApiType.Rtmp, this.currentRtmpIDs).toPromise();
        const pullTargetsRefresh = this.ts.refreshTargets(TargetApiType.Pull, this.currentPullIDs).toPromise();
        const pushTargetsRefresh = this.ts.refreshTargets(TargetApiType.Push, this.currentPushIDs).toPromise();
        const udprtpTargetsRefresh = this.ts.refreshTargets(TargetApiType.UdpRtp, this.currentUdpRtpIDs).toPromise();
        const ristTargetsRefresh = this.ts.refreshTargets(TargetApiType.Rist, this.currentRistIDs).toPromise();
        const srtTargetsRefresh = this.ts.refreshTargets(TargetApiType.Srt, this.currentSrtIDs).toPromise();
        const ndiTargetsRefresh = this.ts.refreshTargets(TargetApiType.Ndi, this.currentNdiIDs).toPromise();
        const cdiTargetsRefresh = this.ts.refreshTargets(TargetApiType.CDI, this.currentCDIIDs).toPromise();
        const jpegxsTargetsRefresh = this.ts.refreshTargets(TargetApiType.JPEGXS, this.currentJPEGXSIDs).toPromise();
        const mediaLiveHttpTargetsRefresh = this.ts
            .refreshTargets(TargetApiType.MediaLiveHttp, this.currentMediaLiveHttpIDs)
            .toPromise();
        const entitlementTargetsRefresh = this.ts
            .refreshTargets(TargetApiType.Entitlement, this.currentEntitlementIDs)
            .toPromise();

        const targetRefresh =
            this.targetName && this.targetApiType
                ? this.ts.refreshTarget(this.selectedTarget.apiType, this.selectedTarget.objId, true).toPromise()
                : Promise.resolve([] as TargetObjectType[]);
        const targetComponentRefresh = new Promise(resolve => {
            if (this.target && this.target.component) {
                (this.target.component as TargetComponent).refresh();
            }
            resolve([] as TargetObjectType[]);
        });

        await Promise.all([
            httpTargetsRefresh,
            rtmpTargetsRefresh,
            pullTargetsRefresh,
            pushTargetsRefresh,
            udprtpTargetsRefresh,
            ristTargetsRefresh,
            srtTargetsRefresh,
            ndiTargetsRefresh,
            cdiTargetsRefresh,
            jpegxsTargetsRefresh,
            mediaLiveHttpTargetsRefresh,
            entitlementTargetsRefresh,
            targetRefresh,
            targetComponentRefresh
        ]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (target: AnyTarget) => Promise<unknown>, options?) {
        const result = await this.modalService.confirmMultiple(action, "TARGET", this.selectedRows, func, options);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple targets");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    async switchChannel() {
        const result = await this.modalService.switchChannel(
            this.selectedRows,
            this.selectedRows[0].adaptive ? "adaptive" : this.selectedRows[0].medialive ? "medialive" : "delivery"
        );
        if (result) {
            this.mixpanelService.sendEvent("switch channel multiple targets");
            this.refresh();
        }
    }

    canSwitchChannel() {
        if (this.selectedRows.length === 0) return false;

        const multiTypes = _.countBy(this.selectedRows, (anyTarget: AnyTarget) => {
            return anyTarget.adaptive ? "adaptive" : "delivery";
        });

        const hasCDIorJPEGXS = this.selectedRows.find(t => t.cdi || t.jpegxs);
        return (!multiTypes.adaptive || !multiTypes.delivery) && !hasCDIorJPEGXS;
    }

    multiDelete() {
        this.multiAction("DELETE", async (target: AnyTarget) => this.ts.deleteTarget(target.target), {
            warning: _.some(this.selectedRows, t => t.dynamic) ? "DYNAMIC_PULL_MULTI_DELETE_WARNING" : null
        });
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (target: AnyTarget) =>
            this.ts.updateTarget(target.target, { is_enabled: enable })
        );
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (target: AnyTarget) =>
            this.ts.updateTarget(target.target, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple("TARGET", this.selectedRows, async (target: AnyTarget, model) => {
            return this.ts.updateTarget(target.target, model);
        });
    }

    startTargetsRefresh() {
        this.targetsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopTargetsRefresh() {
        this.targetsRefreshSubscription.unsubscribe();
    }

    private prepTableData() {
        if (this.targets) {
            const targets = [...this.targets];
            this.targetsBS$.next(targets);
        }
    }

    get allSourcesObservable() {
        return this.targetsBS$.asObservable();
    }

    getTargetColumnTextByRow(row: AnyTarget) {
        let textValue = "";
        if (!row.pull) {
            textValue += row.output_target;
        } else {
            const pullTarget = row.target as ZixiPullTarget;
            if (pullTarget.receiver_id) textValue += pullTarget.receiver.name;
            if (pullTarget.broadcaster_id) textValue += pullTarget.broadcaster.name;
            if ((pullTarget.receiver_id || pullTarget.broadcaster_id) && pullTarget.output_id)
                textValue += pullTarget.output_name;
            if (!pullTarget.receiver_id && !pullTarget.broadcaster_id) textValue += pullTarget.receiver_name;
        }
        return textValue;
    }

    toggleInsights() {
        this.showInsights = !this.showInsights;
        if (this.showInsights) {
            this.mixpanelService.sendEvent("view target insights", { ids: this.selectedRows.map(row => row.id) });
        }
    }

    onSelectedRowsChange(selectedRows: AnyTarget[]) {
        this.multiSelectDisasterRecovery = false;

        const isSelectionDRable = selectedRows.every(
            row => this.ts.getDisasterRecoveryState(row.target) !== RecoveryState.none
        );

        this.multiSelectDisasterRecovery = !!isSelectionDRable;
    }

    disasterRecoveryClick() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = this.selectedRows.map(t => ({
            id: t.target.id,
            type: t.type
        }));
    }
}
