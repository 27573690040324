import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { urlBuilder } from "@zixi/shared-utils";
import { Breadcrumb } from "src/app/services/breadcrumbs.service";
import { TranslateService } from "@ngx-translate/core";
import { TargetsService } from "./targets.service";

@Injectable({
    providedIn: "root"
})
export class TargetBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private targetsService: TargetsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.targetsService
            .refreshTarget(
                this.targetsService.apiTypeSwitch(route.params["type"]),
                urlBuilder.decode(route.params["targetId"]),
                false
            )
            .pipe(
                map(target => [
                    {
                        label: target.name,
                        url: state.url
                    }
                ])
            );
    }
}

@Injectable({
    providedIn: "root"
})
export class TargetFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return Observable.create(observe => {
            observe.next([
                {
                    label: this.translate.instant("TARGETS"),
                    url: "/" + route.url[0].path
                },
                {
                    label: route.params.name,
                    url:
                        "/" +
                        route.url[0].path +
                        "/" +
                        route.url[1].path +
                        "/" +
                        route.url[2].path +
                        "/" +
                        route.url[3].path
                },
                {
                    label:
                        (route.params.action ? this.translate.instant(route.params.action.toUpperCase()) + " " : "") +
                        this.translate.instant("TARGET")
                }
            ]);
            observe.complete();
        });
    }
}
