<content-loading-animation *ngIf="loading"></content-loading-animation>
<form
    id="form"
    #form="ngForm"
    (ngSubmit)="
        (isEdit || form.valid) && !nameControl.invalid && !tagsControl.invalid && !subscriberIdControl.invalid && !dataPrecentControl.invalid && onSubmit(form)
    "
    *ngIf="!loading"
>
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_ENTITLEMENT_TARGET" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_ENTITLEMENT_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_ENTITLEMENT_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="isEdit || saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="mediaconnect_flow_select"
                                >{{ "AWS_MEDIACONNECT_FLOW" | translate }} <fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                            >
                            <zx-mediaconnect-flow-select
                                name="mediaconnect_flow_select"
                                [(model)]="mediaconnect_flow_id"
                                [clearable]="true"
                                [required]="!isEdit"
                                [isParentFormSubmitted]="form.submitted"
                                [disabled]="isEdit"
                            ></zx-mediaconnect-flow-select>
                        </div>

                        <!-- Data transfer subscriber fee precent input -->
                        <div class="form-group">
                            <app-input-generic-field
                                zmid="subscriber_fee_precent"
                                [formControl]="dataPrecentControl"
                                circleTip="{{ 'DATA_SUBSCRIBER_PRECENTAGE_DESC' | translate }}"
                                [textFieldName]="'DATA_SUBSCRIBER_PRECENTAGE' | translate"
                                type="number"
                                [disabled]="isEdit || saving"
                                patternErrorMessage="{{ 'MUST_BE_BETWEEN_0_100' | translate }}"
                                [isParentFormSubmitted]="form.submitted"
                            >
                            </app-input-generic-field>
                        </div>

                        <!-- Subscriber ID input -->
                        <div class="form-group">
                            <app-input-generic-field
                                zmid="subscriber_id"
                                [formControl]="subscriberIdControl"
                                [textFieldName]="'SUBSCRIBER_ACCOUNT_ID' | translate"
                                [disabled]="saving"
                                [isParentFormSubmitted]="form.submitted"
                                [patternErrorMessage]="'MUST_BE_AWS_ACCOUNT_ID' | translate"
                                type="text"
                            ></app-input-generic-field>
                        </div>
                    </section>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
